import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// import the gif images
import reportAnIssueGif from "assets/images/gifs/report-an-Issue-2.gif";
import provideFeedbackGif from "assets/images/gifs/provide-feedback.gif";
import trainingVideosGif from "assets/images/gifs/training-videos.gif";
import rawDataGif from "assets/images/gifs/raw-data.gif";
import dashboardGif from "assets/images/gifs/dashboard.gif";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// import @mui material icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';

// import custom sidenav style file
import "examples/Sidenav/styles/sidenav.css";

// import antd css and modal
import 'antd/dist/antd.css';
import { Modal, Row, Col, Image, Card } from 'antd';

// import devextreme Button
import { Button } from "devextreme-react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "utils/context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openItems, setOpenItems] = useState([]);
  const [isSupportFeedbackCenterModallVisible, setIsSupportFeedbackCenterModalVisible] = useState(false);
  const [isAnalyticsModalVisible, setIsAnalyticsModalVisible] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const pathArray = pathname.split("/")
  let activePath = pathArray.slice(pathArray.length - 1)[0];
  const openItemsData = localStorage.getItem('openItemsData')
  let textColor = "white";

  // if (activePath === "job-description") {
  //   // make manage-jobs nav active when job-description is open
  //   activePath = "manage-jobs"
  // }
  // else if (activePath === "manage-headcount") {
  //   // make manage-headcounts nav active when manage-headcount is open
  //   activePath = "manage-headcounts"
  // }

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const history = useNavigate()
  const loc = useLocation()

  useEffect(() => {
    // when page reloads in case openItemsData(localStorage) have items open sidenav items
    if (openItemsData && openItemsData !== null) {
      updateOpenNavItems(JSON.parse(openItemsData));
    }
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {

      if (window.location.pathname === '/views/manage-headcount') {
        const wdth = localStorage.getItem('hcFormWidth');
        const hght = localStorage.getItem('hcFormHeight');
        const screenSize = localStorage.getItem('screenSize');
        const element = document?.querySelector('.css-e6tepc');

        setTimeout(() => {
          if (window.innerWidth > 1200) {
            if (wdth && hght && screenSize && window.innerWidth === parseInt(screenSize, 10)) {
              if (element) {
                element.style.setProperty('width', `${wdth}px`, 'important');
                element.style.setProperty('height', `${hght}px`, 'important');
              }
            }

            const elements = document.querySelectorAll('.dx-scrollable-native.dx-scrollable-both');
            elements.forEach(el => {
              el.style.overflowX = 'hidden';
              const childElements = el.querySelectorAll('.dx-scrollable-wrapper > .dx-scrollable-container');
              childElements.forEach(childElement => {
                childElement.style.overflowX = 'hidden';
              });
            });
          } else {
            if (element) {
              element.style.setProperty('width', '100%', 'important');
              element.style.setProperty('height', 'auto', 'important');
            }
          }

          // Ensure button visibility on resize
          const buttonContainer = document.querySelector('.button-container');
          if (buttonContainer) {
            buttonContainer.style.display = 'flex';
          }
        }, 500);
      }
      else {
        setMiniSidenav(dispatch, window.innerWidth < 1200);
        setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
        setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
      }
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // handle show modal
  const showModal = (key) => {
    if (key)
      if (key === "analytics")
        setIsAnalyticsModalVisible(true);
      else if (key === "support-feedback-center")
        setIsSupportFeedbackCenterModalVisible(true);
  };

  // handle hide modal
  const hideModal = (key) => {
    if (key)
      if (key === "analytics")
        setIsAnalyticsModalVisible(false);
      else if (key === "support-feedback-center")
        setIsSupportFeedbackCenterModalVisible(false);
  };

  // handle open and close sidenav items
  const updateOpenNavItems = (key, level, route) => {
    if (key) {
      if (openItems.length) {
        if (openItems.includes(key)) {
          // in case the item is already opened
          let updatedOpenItems;

          // in case a parent item has opened items inside, close them and close the parent item
          let filteredOpenItems = openItems
          updatedOpenItems = filteredOpenItems
          if (route === null) {
            updatedOpenItems = filteredOpenItems.slice(0, level)
            setOpenItems(updatedOpenItems)
          }
          if (route !== null) {
            localStorage.setItem('openItemsData', JSON.stringify(updatedOpenItems))
          }
        }
        else {
          // in case the item is not opened yet
          let updatedOpenItems;

          // in case another parent item is opened with the same level close it
          let filteredOpenItems = openItems
          filteredOpenItems = filteredOpenItems.slice(0, level)
          // open the new parent item
          updatedOpenItems = [...filteredOpenItems, key]

          setOpenItems(updatedOpenItems)
          if (route !== null) {
            localStorage.setItem('openItemsData', JSON.stringify(updatedOpenItems))
          }
        }
      }
      else {
        // in case no items is opened yet
        if (typeof key !== "object") {
          key = key.split()
        }
        setOpenItems(key)
        if (route !== null) {
          localStorage.setItem('openItemsData', JSON.stringify(key))
        }
      }
    }
  }

  // Render sidenav items
  const renderSidenavItems = (routes) => routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route, level, isVisible = true }) => {
    let returnNavItems

    if (collapse && isVisible) {
      // Render sidenav items that have collapsed children
      returnNavItems = (
        <li key={key} className={`sidenav-item ${openItems.includes(key) ? "open" : ""}`}>
          <div className="sidenav-item-content">
            <span className="sidenav-item-title"
              onClick={() => {
                updateOpenNavItems(key, level, null)
              }}>
              {icon && icon}
              {name}
              <KeyboardArrowDownIcon fontSize="small" className="collapse-arrow" />
            </span>
            <ul className="sidenav-nested-items">
              {renderSidenavItems(collapse)}
            </ul>
          </div>
        </li>
      )
    } else if (noCollapse && route && isVisible) {
      // Render sidenav items that doesn't have collapsed children
      returnNavItems = (
        <li key={key} className={`sidenav-item ${key === activePath ? "active" : ""}`}>
          <NavLink to={route} className="sidenav-item-content">
            <span className="sidenav-item-title"
              onClick={() => {
                updateOpenNavItems(key, level, route)
              }}>
              {icon && icon}
              {name}
            </span>
          </NavLink>
        </li>
      );
    } else if (href && isVisible) {
      // Render href sidenav items
      returnNavItems = (
        <li key={key} className={`sidenav-item ${key === activePath ? "active" : ""}`}>
          <Link
            className="sidenav-item-content"
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            <span className="sidenav-item-title">
              {icon && icon}
              {name}
            </span>
          </Link>
        </li>
      );
    } else if (type === "title") {
      // Render title sidenav items
      if (title && isVisible) {
        returnNavItems = (
          <li key={key}>
            <MDTypography
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={2}
              pr={2}
              pt={1}
              pb={1}
            >
              {title}
            </MDTypography>
          </li>
        );
      }
    } else if (type === "modal") {
      // Render modal sidenav items
      if (name && isVisible) {
        returnNavItems = (
          <li className="sidenav-item" key={key}>
            <div className="sidenav-item-content">
              <span className="sidenav-item-title"
                onClick={() => {
                  showModal(key)
                }}>
                {icon && icon}
                {name}
              </span>
            </div>
          </li>
        );
      }
    } else if (type === "divider") {
      // Render divider sidenav items
      returnNavItems = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }
    return returnNavItems
  });

  return (
    <>
      <SidenavRoot
        className={"sidenav-wrapper"}
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={4} textAlign="center">
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.1}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h6" color="white">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox component={NavLink} to="/views/home" display="flex" alignItems="center">
            {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
            <MDBox
              width={!brandName && "100%"}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              {brandName && <MDBox component="img" src={brandName} alt="Publicis Groupe" width="6.875rem" />}
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List className="sidenav-main-container">
          {renderSidenavItems(routes)}
          <li
            className="sidenav-item"
            key={"logout"}
            onClick={() => {
              localStorage.clear()
              sessionStorage.clear()
              history(loc.pathname)
              window.location.reload()
            }}>
            <div className="sidenav-item-content">
              <span className="sidenav-item-title">
                <LogoutIcon fontSize="medium" />
                Sign Out
              </span>
            </div>
          </li>
        </List>
      </SidenavRoot>

      {/* Support & Feedback Center Modal */}
      <Modal className="support-feedback-center-modal" maskClosable={false} title={"Support & Feedback Center"} width={900} open={isSupportFeedbackCenterModallVisible} onCancel={() => hideModal("support-feedback-center")}
        footer={<Button stylingMode={"contained"} type={"danger"} text={"Cancel"} onClick={() => hideModal("support-feedback-center")} />}
      >
        <Row justify="center" align="middle" gutter={[24, 24]}>
          <Col align="middle" md={8} xs={24}>
            <Link
              href={'https://apps.powerapps.com/play/e/default-d52c9ea1-7c21-47b1-82a3-33a74b1f74b8/a/d8cc0e5c-2b2e-409a-a1cb-c2459d63f91c?tenantId=d52c9ea1-7c21-47b1-82a3-33a74b1f74b8'}
              target="_blank"
              color={'light'}
              rel="noreferrer"
              style={{ display: "inline-block" }}
            >
              <Card
                style={{ width: "240px", borderRadius: "0.75rem", boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)" }}
                cover={
                  <Image
                    title="Help Desk"
                    alt="Help Desk"
                    width={200}
                    src={reportAnIssueGif}
                    preview={false}
                    style={{ paddingTop: "2px" }}
                  />
                }
              >
                <Card.Meta style={{ textAlign: "center", justifyContent: "center" }} title="Help Desk" />
              </Card>
            </Link>
          </Col>
          <Col align="middle" md={8} xs={24}>
            <Link
              href={'https://forms.office.com/r/XH3CHg0miV'}
              target="_blank"
              color={'light'}
              rel="noreferrer"
              style={{ display: "inline-block" }}
            >
              <Card
                style={{ width: "240px", borderRadius: "0.75rem", boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)" }}
                cover={
                  <Image
                    title="Feedback Portal"
                    alt="Feedback Portal"
                    width={200}
                    src={provideFeedbackGif}
                    preview={false}
                    style={{ paddingTop: "2px" }}
                  />
                }
              >
                <Card.Meta style={{ textAlign: "center", justifyContent: "center" }} title="Feedback Portal" />
              </Card>
            </Link>
          </Col>
          <Col align="middle" md={8} xs={24}>
            <Link
              href={'https://publicisgroupe-my.sharepoint.com/personal/mueahmad_publicisgroupe_net/Lists/TEOS%20Training/AllItems.aspx?viewid=136be5dd%2D2ced%2D4a0a%2Db105%2D9355b4728df0&playlistLayout=playback&itemId=2'}
              target="_blank"
              color={'light'}
              rel="noreferrer"
              style={{ display: "inline-block" }}
            >
              <Card
                style={{ width: "240px", borderRadius: "0.75rem", boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)" }}
                cover={
                  <Image
                    title="Training Portal"
                    alt="Training Portal"
                    width={200}
                    src={trainingVideosGif}
                    preview={false}
                    style={{ paddingTop: "2px" }}
                  />
                }
              >
                <Card.Meta style={{ textAlign: "center", justifyContent: "center" }} title="Training Portal" />
              </Card>
            </Link>
          </Col>
        </Row>
      </Modal>

      {/* Analytics Modal */}
      <Modal Modal className="analytics-modal" maskClosable={false} title={"Analytics"} width={600} open={isAnalyticsModalVisible} onCancel={() => hideModal("analytics")
      }
        footer={<Button stylingMode={"contained"} type={"danger"} text={"Cancel"} onClick={() => hideModal("analytics")} />}
      >
        <Row justify="center" align="middle" gutter={[24, 24]}>
          <Col align="middle" md={12} xs={24}>
            <Link
              href={'https://app.powerbi.com/groups/2bc3943c-a5d3-4a7c-a8c8-61d6133e6186/reports/102f77aa-32a5-4c4d-8c93-59a533bbd0f4/ReportSection55494c4d615702764004?experience=power-bi'}
              target="_blank"
              color={'light'}
              rel="noreferrer"
              style={{ display: "inline-block" }}
            >
              <Card
                style={{ width: "240px", borderRadius: "0.75rem", boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)" }}
                cover={
                  <Image
                    title="Dashboard"
                    alt="Dashboard"
                    width={200}
                    src={dashboardGif}
                    preview={false}
                    style={{ paddingTop: "2px" }}
                  />
                }
              >
                <Card.Meta style={{ textAlign: "center", justifyContent: "center" }} title="Dashboard" />
              </Card>
            </Link>
          </Col>
          <Col align="middle" md={12} xs={24}>
            <Link
              href={'https://app.powerbi.com/groups/2bc3943c-a5d3-4a7c-a8c8-61d6133e6186/reports/102f77aa-32a5-4c4d-8c93-59a533bbd0f4/ReportSectiondcb14d9011f22c96503e?experience=power-bi'}
              target="_blank"
              color={'light'}
              rel="noreferrer"
              style={{ display: "inline-block" }}
            >
              <Card
                style={{ width: "240px", borderRadius: "0.75rem", boxShadow: "0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)" }}
                cover={
                  <Image
                    title="Reports"
                    alt="Reports"
                    width={200}
                    src={rawDataGif}
                    preview={false}
                    style={{ paddingTop: "2px" }}
                  />
                }
              >
                <Card.Meta style={{ textAlign: "center", justifyContent: "center" }} title="Reports" />
              </Card>
            </Link>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
